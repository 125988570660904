import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/sysEnterprise',
    method: 'post',
    data
  })
}

export function addSub(data) {
  return request({
    url: 'api/sysEnterprise/sub',
    method: 'post',
    data
  })
}

export function getSub() {
  return request({
    url: "api/enterprisesub/list",
    method: "get"
  })
}

export function del(id) {
  return request({
    url: 'api/sysEnterprisesub/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/sysEnterprise',
    method: 'put',
    data
  })
}

export function getSelfEnterprise() {
  return request({
    url: 'api/sysEnterprise/self',
    method: 'get'
  })
}

export function getByLicenseCode(code) {
  return request({
    url: 'api/sysEnterprise/licenseCode/' + code,
    method: 'get'
  })
}

export function getByName(name) {
  return request({
    url: 'api/sysEnterprise/name/' + name,
    method: 'get'
  })
}
