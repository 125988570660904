<template>
    <el-dialog :append-to-body="true" :visible.sync="dialog" title="更多ERP编码" :close-on-click-modal="false" @closed="cancel" width="600px">    
      <el-form ref="form" :model="form" size="small" label-width="115px" label-suffix=":" v-if="form" @submit.native.prevent>
          <div>   
            <!-- <el-input v-model.trim="form.erpId" :maxlength="25" placeholder="输入ERP编码以获取经销商信息" style="width:300px"/>  -->
            <el-input v-model.trim="form.erpId" :maxlength="25" placeholder="输入ERP编码以添加经销商信息" style="width:300px" :readonly="searching" @keypress.enter.native="checkErpId" @change="checkErpId">
              <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="checkErpId"></el-button>
            </el-input>            
          </div>
          &nbsp;    
          <el-table v-loading="loading" row-key="id" :data="data" size="mini" border>
            <el-table-column prop="erpId" label="ERP编码" min-width="100"/>
            <el-table-column prop="name" label="名称" min-width="200"/>
            <el-table-column label="启/停用" width="70px">
              <template slot-scope="scope">
                 <el-switch  v-model="scope.row.enable" @change="enable(scope.row)"></el-switch> 
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="40">
              <div class="row-commands" slot-scope="scope">
               <el-popover :ref="scope.row.id" placement="top" width="180">
                <p>确定删除本条数据吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                  <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
                </div>
                <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
              </el-popover>
              </div>
            </el-table-column>
          </el-table>          
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">关闭</el-button>
      </div>
    </el-dialog>
</template>

<script>
import { getByErpCode,addErp,editErp,delErp } from "@/api/distributor";
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  data() {    
    return {
      delLoading:false,
      loading: false,
      searching:false,
      dialog: false,
      form:{},
    };
  },
  // mounted() {
  //   this.init();
  // },
  methods: {
    beforeInit() {
      this.url = "api/distributorErps";
      const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        {entId:this.form.entId}
      );
      return true;
    },
    enable(data){
      editErp(data).then(_=>{
        this.$notify({
            title: "操作成功",
            type: "success",
            duration: 2500,
          });
          // this.init();          
      }).catch(_=>{

      })
    },
    cancel() {
      this.form = null;
    },
    subDelete(id) {
      this.delLoading = true;
      delErp(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    doSave() {      
      addErp(this.form).then(_=>{
        this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500,
          });
          this.form.erpId="";
          this.init();          
      }).catch(_=>{

      })
    },
    resetForm(entId,accountId,mainErpId) {
      this.form = {entId:entId,erpAccountId:accountId,name:null,erpId:null,mainErpId:mainErpId};
      this.init();
      this.dialog = true;  
    },

    checkErpId(){
      if(this.searching) return;
      if(this.form.mainErpId===this.form.erpId) {
        this.$message({
          showClose: true,
          message: '此经销商已使用此编码作为主ERP编码,请重新输入',
          type: 'error'
        });
        return;
      }
      if(this.form.erpId){
        let _exist = false;
        this.data.forEach(item => {
          if(item.erpId===this.form.erpId){
            _exist = true;;
          }
      });
      if(_exist) {
        this.$message({
          showClose: true,
          message: '此编码已存在,请重新输入',
          type: 'error'
        });
        return;
      }
        this.searching = true;
        getByErpCode(this.form.erpId,this.form.erpAccountId)
          .then((res) => {
            if (res) {              
              this.$confirm(`确定使用：编码：${res.erpId}，名称：${res.alias} 吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.form.erpId = res.erpId;
                this.form.name = res.alias;
                this.form.enable = true;
                this.doSave();
              }).catch(() => {
                   
              });              
            } 
          })
          .catch((e) => {
            // this.errors.erpId1 = true;
          })
          .finally((_) => {            
            this.searching = false;
          });
      }
    },
  }
};
</script>

