import request from '@/utils/request'

export function add (data) {
  return request({
    url: 'api/distributor',
    method: 'post',
    data
  })
}

export function del (id) {
  return request({
    url: 'api/distributor/' + id,
    method: 'delete'
  })
}

export function edit (data) {
  return request({
    url: 'api/distributor',
    method: 'put',
    data
  })
}

export function batchImport (data) {
  return request({
    url: "api/distributors",
    method: 'post',
    data
  })
}

export function getByErpCode (erpId,accountId) {
  return request({
    url: "api/distributor/getByErpCode",
    method: "get",
    params: {
      code: erpId,
      accountId:accountId
    }
  })
}

export function addErp(data) {
  return request({
    url: 'api/distributorErps',
    method: 'post',
    data
  })
}

export function delErp (id) {
  return request({
    url: 'api/distributorErps/' + id,
    method: 'delete'
  })
}

export function editErp (data) {
  return request({
    url: 'api/distributorErps',
    method: 'put',
    data
  })
}