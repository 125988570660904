<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增经销商信息' : '编辑经销商信息'" width="790px" @closed="handleClosed">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="130px" label-suffix=":" v-if="form">
      <div class="h" v-if="$erp.enable()">
        <el-form-item label="ERP账套" prop="erpAccountId" style="width: 50%;" v-if="$erp.enable()">
          <quick-select v-model="form.erpAccountId" value-field="id" filterable url="api/distributor/erp/account" placeholder="选择ERP账套" style="width: 100%;" />
        </el-form-item>
        <el-form-item prop="erpId" label="ERP编码" style="width: 50%;">
          <el-input v-if="form.erpAccountId" v-model.trim="form.erpId" :maxlength="20" :readonly="searching" @keypress.enter.native="getByErpId" @change="getByErpId">
            <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="getByErpId"></el-button>
          </el-input>
          <div class="fc-g" v-else>请先选择ERP账套</div>
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item prop="erpDeptCode" label="ERP部门编码" label-width="115px" style="width: 50%;" v-if="erpType === 'kingkoil'">
          <el-input v-model="form.erpDeptCode" maxlength="50" />
        </el-form-item>
        <el-form-item prop="licenseCode" label="证件号码" style="width: 50%;">
          <el-input v-model.trim="form.licenseCode" :readonly="searching" placeholder="输入营业执照号或个人身份证号" @keypress.enter.native="getByLicenseCode" @change="getByLicenseCode">
            <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="getByLicenseCode"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label label-width="115px" style="width: 50%;" v-if="erpType === 'serta' && form.erpAccountId">
          <!-- <el-input v-model.trim="form.erpId1" :maxlength="20" :readonly="searching" @keypress.enter.native="checkErpId" @change="checkErpId">
            <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="checkErpId"></el-button>
          </el-input>-->
          <el-button type="text" v-if="form.enterpriseId" @click="handlerMore">更多ERP编码维护</el-button>
        </el-form-item>
      </div>
      <el-form-item label="企业名称" prop="fullName" v-if="erpType !== 'serta'">
        <el-input v-model="form.fullName" maxlength="20" />
      </el-form-item>
      <el-form-item label="企业简称" prop="name" v-if="erpType !== 'serta'">
        <el-input v-model="form.name" maxlength="20" />
      </el-form-item>
      <el-form-item label="企业名称" prop="name" v-if="erpType === 'serta'">
        <el-input v-model="form.name" maxlength="20" />
      </el-form-item>
      <div class="h">
        <el-form-item label="企业类型" prop="enterpriseType" style="width: 50%;">
          <quick-select v-model="form.enterpriseType" filterable url="api/dictDetail" display-field="label" :params="{ dictName: 'enterprise_type' }" style="width: 100%" />
        </el-form-item>
        <el-form-item label="所属区域" prop="areaId" style="width: 50%;">
          <tree-picker v-model="form.areaId" url="api/area" flat style="width: 100%" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="经销商类型" prop="level" style="width: 50%;">
          <quick-select v-model="form.level" filterable url="api/distributorLeve/list" style="width: 100%" />
        </el-form-item>
        <el-form-item label="停止合作日期" prop="stopDate" style="width: 50%;">
          <el-date-picker type="date" v-model="form.stopDate" value-format="timestamp" style="width: 100%" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="订单支付限制" prop="orderPayType" style="width: 50%;">
          <el-select v-model="form.orderPayType" style="width: 100%">
            <el-option value="all" label="不进行支付限制" />
            <el-option value="offline" label="只能进行线下支付" />
            <el-option value="online" label="只能进行在线支付" />
          </el-select>
        </el-form-item>

        <el-form-item label="其他支付限制" prop="otherPayType" style="width: 50%;">
          <div class="h">
            <el-select v-model="form.otherPayType" style="width: 100%">
              <el-option value="all" label="不进行支付限制" />
              <el-option value="offline" label="只能进行线下支付" />
              <el-option value="online" label="只能进行在线支付" />
            </el-select>
            <el-tooltip class="item" effect="dark" content="充值、费用等除采购订单外的支付限制" placement="top-start">
              <el-button type="text" icon="el-icon-info"></el-button>
            </el-tooltip>
          </div>
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="入驻年月" prop="joinDate">
          <el-date-picker v-model="form.joinDate" type="month" format="yyyy-MM" value-format="yyyy-MM" style="width: 247px" />
        </el-form-item>
        <el-form-item label="创建类型" prop="createType">
          <quick-select v-model="form.createType" filterable url="api/dictDetail?dictName=create_type" value-field="id" display-field="label" placeholder="请选择" style="width: 240px;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="城市类型" prop="cityType">
          <quick-select v-model="form.cityType" filterable url="api/dictDetail?dictName=city_type" value-field="id" display-field="label" placeholder="请选择" style="width: 247px;" />
        </el-form-item>
        <el-form-item label="区县类型" prop="districtType">
          <quick-select v-model="form.districtType" filterable url="api/dictDetail?dictName=district_type" value-field="id" display-field="label" placeholder="请选择" style="width: 240px;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="联系邮箱" prop="mail">
          <el-input v-model="form.mail" style="width: 247px;" />
        </el-form-item>

        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone" style="width: 240px;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="运营人员工号" prop="managePersonCode">
          <el-input v-model="form.managePersonCode" style="width: 247px;" />
        </el-form-item>
        <el-form-item label="运营人员名称" prop="managePerson">
          <el-input v-model="form.managePerson" style="width: 240px;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="拓展人员工号" prop="extendPersonCode">
          <el-input v-model="form.extendPersonCode" style="width: 247px;" />
        </el-form-item>
        <el-form-item label="拓展人员名称" prop="extendPerson">
          <el-input v-model="form.extendPerson" style="width: 240px;" />
        </el-form-item>
      </div>
      <el-form-item label="区域负责人" key="user_manager" class="flex">
        <datagrid-picker v-model="form.managerIds" multiple submit url="api/users" value-field="id" display-field="userRealName" query-value-field="ids" :query-define="userQueryDefine" :show-index="false" placeholder="请选择" style="width: 100%;">
          <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
            <el-input :maxlength="20" v-model="userQueryDefine.blurry" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入账号或名称进行搜索" style="width: 240px;" />
            <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
          </div>
          <el-table-column prop="username" label="账号" width="130" />
          <el-table-column prop="userRealName" label="姓名" width="160" />
          <el-table-column label="部门">
            <template slot-scope="scope">{{ scope.row.dept ? scope.row.dept.name:""}}</template>
          </el-table-column>
          <el-table-column label="角色" :formatter="r=>{return showRoles(r.roles)}" />
          <!-- <el-table-column label="所在地区" min-width="200" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}" /> -->
        </datagrid-picker>
      </el-form-item>
      <div class="h">
        <el-form-item class="flex" label="所在地区" prop="provinceCode">
          <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 100%;" @change="handleProvinceChange" />
        </el-form-item>
        <el-form-item label-width="12px" prop="cityCode" style="width: 180px;">
          <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
          <el-input disabled placeholder="请先选择省份" v-else />
        </el-form-item>
        <el-form-item label-width="12px" prop="districtCode" style="width: 180px;">
          <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
          <el-input disabled placeholder="请先选择城市" v-else />
        </el-form-item>
      </div>

      <el-form-item label="详细地址">
        <el-input v-model="form.address" maxlength="100" />
      </el-form-item>
      <!-- <div class="h">
        <el-form-item label="U9出货仓库代码">
          <el-input v-model="form.shipWarehouseCode" maxlength="50" style="width: 247px;" />
        </el-form-item>
        <el-form-item label="交期增加天数">          
          <el-input-number type="number" v-model="form.deliveryAddDays" :max="365" :step="1" :precision="0" controls-position="right" class="flex" style="width: 240px;" />
        </el-form-item>
      </div> -->
      <el-form-item label="经营范围">
        <el-input type="textarea" v-model="form.businessScope" :rows="3" resize="none" maxlength="250" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit" v-if="!readOnly">确认</el-button>
    </div>
    <moreErp ref="moreErp" />
  </el-dialog>
</template>

<script>
import { add, edit, getByErpCode } from "@/api/distributor";
import { getByLicenseCode } from "@/api/sysEnterprise";
import moreErp from "./moreErp";
import request from "@/utils/request";

export default {
  components: { moreErp },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    let checkHasErpIdError = (rule, val, callback) => {
      if (this.errors.erpId) {
        callback("未找到指定的经销商信息");
      } else {
        callback();
      }
    };
    let checkHasLicenseCodeError = (rule, val, callback) => {
      if (this.errors.licenseCode) {
        callback("未找到指定的企业信息");
      } else {
        callback();
      }
    };
    return {
      referenceLoading: false,
      erpType: null,
      loading: false,
      searching: false,
      dialog: false,
      visible: false,
      dealerData: [],
      form: null,
      readOnly: false,
      errors: {
        erpId: false,
      },
      userQueryDefine: {
        blurry: null,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        fullName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        areaId: [
          { required: true, message: "请选择所属区域", trigger: "change" },
        ],
        licenseCode: [
          { required: true, message: "请输入企业代码", trigger: "blur" },
        ],
        provinceCode: [
          { required: true, message: "请选择省份", trigger: "blur" },
        ],
        cityCode: [{ required: true, message: "请选择城市", trigger: "blur" }],
        districtCode: [
          { required: true, message: "请选择地区", trigger: "blur" },
        ],
        enterpriseType: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        level: [{ required: true, message: "请选择经销商类型" }],
        registerTime: [{ required: true, message: "请选择登记时间" }],
        orderPayType: [{ required: true, message: "请选择订单支付限制" }],
        otherPayType: [{ required: true, message: "请选择其他支付限制" }],
        //otherPayType
      },
    };
  },

  methods: {
    handleProvinceChange() {
      this.form.cityCode = null;
      this.form.districtCode = null;
      if (
        this.form.provinceCode &&
        ["320000", "330000", "340000"].includes(this.form.provinceCode)
      ) {
        this.form.shipWarehouseCode = "20510";
        this.form.deliveryAddDays = 3;
      } else {
        this.form.shipWarehouseCode = "";
        this.form.deliveryAddDays = null;
      }
    },
    handleClosed() {
      this.form = null;
      this.errors.erpId = false;
      this.readOnly = false;
    },
    handlerMore() {
      this.$refs.moreErp &&
        this.$refs.moreErp.resetForm(
          this.form.enterpriseId,
          this.form.erpAccountId,
          this.form.erpId
        );
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      add(this.form)
        .then((res) => {
          this.$notify({
            title: "添加经销商成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.$notify({
            title: "编辑经销商成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    resetForm(erpType, form, readOnly) {
      this.erpType = erpType;
      if (!form) {
        form = {
          id: null,
          licenseCode: "",
          name: "",
          provinceCode: "",
          proveinceName: "",
          cityCode: "",
          cityName: "",
          districtCode: "",
          districtName: "",
          address: "",
          alias: "",
          level: "",
          stopDate: "",
          belongTo: "",
          registerTime: "",
          deptId: "",
          erpId: "",
          enterpriseType: "",
          businessScope: "",
          orderPayType: "all",
          managerIds: [],
          managePerson: "",
          managePersonCode: "",
          extendPerson: "",
          extendPersonCode: "",
        };
      }
      if (!form.managerIds || !form.managerIds.length) {
        this.referenceLoading = true;
        request({
          url: "api/distributor/managers",
          method: "get",
          params: { disEntId: form.enterpriseId },
        })
          .then((res) => {
            if (res) {
              form.managerIds = res;
            }
            this.form = form;
            this.dialog = true;
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      } else {
        this.form = form;
        this.dialog = true;
      }
      if (readOnly) {
        this.readOnly = readOnly;
      }
    },
    getByErpId() {
      if (this.form.erpId) {
        this.searching = true;
        this.form.name = "";
        this.errors.erpId = false;
        this.$refs.form.validateField("erpId");
        getByErpCode(this.form.erpId, this.form.erpAccountId)
          .then((res) => {
            if (res) {
              this.form.name = res.alias.replace(
                /[^\d\w\.\-\_\u4e00-\u9fa5]/gi,
                ""
              );
              this.form.fullName = res.fullName.replace(
                /[^\d\w\.\-\_\u4e00-\u9fa5]/gi,
                ""
              );
              this.errors.erpId = false;
              if (res.erpDeptCode) {
                this.form.erpDeptCode = res.erpDeptCode;
              }
            } else {
              this.errors.erpId = true;
            }
          })
          .catch((e) => {
            this.errors.erpId = true;
          })
          .finally((_) => {
            this.searching = false;
            this.$refs.form.validateField("erpId");
          });
      }
    },

    getByLicenseCode() {
      if (this.form.licenseCode) {
        this.searching = true;
        getByLicenseCode(this.form.licenseCode)
          .then((res) => {
            if (res) {
              Object.assign(this.form, res);
            }
          })
          .finally((_) => {
            this.searching = false;
          });
      }
    },
    showRoles(data) {
      let n = "";
      if (data.length > 1) {
        data.forEach((o) => {
          n += o.name + ",";
        });
      } else if (data.length == 1) {
        data.forEach((o) => {
          n = o.name;
        });
      }
      return n;
    },
  },
};
</script>
